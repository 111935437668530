import { useCallback, useEffect, useState } from "react";
import logo from "./assets/images/frisia-white-logo.svg";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFaceFrown,
  faFaceGrin,
  faFaceSmile,
} from "@fortawesome/free-regular-svg-icons";
import amplifiqueService from "./services/amplifique-service";
import QuestionRow from "./components/QuestionRow";
import { Answer, Question } from "./model/search";
import maximize from "./assets/images/maximize-solid.svg";
import Modal from "@mui/material/Modal";
import frisiaService from "./services/frisia-service";
import { IFrisiaSurvey } from "./model/frisiaSurvey";

function App() {
  const [ratingNumber, setRatingNumber] = useState<number>();
  const [showFaces, setShowFaces] = useState<boolean>(false);
  const [showOthersAmpQuestions, setShowOthersAmpQuestions] =
    useState<boolean>(false);
  const [surveyFrisia, setSurveyFrisia] = useState<IFrisiaSurvey>();
  const [surveyAnswerId, setSurveyAnswerId] = useState<string>();
  const [answersArray, setAnswersArray] = useState<Answer[]>([]);
  const [questionsArray, setQuestionsArray] = useState<Question[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [greatMessage, setGreatMessage] = useState<string>(
    "Obrigado pela resposta! Ficamos felizes em saber que você foi bem atendido(a)."
  );
  const [goodMessage, setGoodMessage] = useState<string>(
    "Obrigado pela resposta! Ficamos felizes em saber que você foi bem atendido(a)."
  );
  const [badMessage, setBadMessage] = useState<string>(
    "Sentimos pela falha! Sua avaliação ajudará nas providências necessárias em nosso atendimento"
  );
  const [inFullscreen, setInFullscreen] = useState<boolean>(false);

  const { pathname } = window.location;

  const eraseAnswers = () => {
    setRatingNumber(undefined);
    setAnswersArray([]);
    setShowOthersAmpQuestions(false);
  };

  const getAmplifiqueData = (surveyFrisia: IFrisiaSurvey) => {
    amplifiqueService
      .getSearchByClient(
        {
          name: surveyFrisia.nomeCliente,
          email: surveyFrisia.emailCliente,
          company: surveyFrisia.perfilCliente,
          phone: `+55DD${surveyFrisia.telefoneCliente.replace(/\D/g, "")}`,
          custom_fields: {
            nomecliente: surveyFrisia.nomeCliente,
            emailcliente: surveyFrisia.emailCliente,
            telefonecliente: surveyFrisia.telefoneCliente,
            tipocliente: surveyFrisia.perfilCliente,
            usuariofrentedecaixa: surveyFrisia.usuarioSolicitacao,
            datacompra: surveyFrisia.dataHoraSolicitacao,
            codigosistemaorigem: surveyFrisia.codigoSistemaOrigem,
          },
        },
        surveyFrisia.dsToken
      )
      .then((response) => {
        setSurveyAnswerId(response.surveyAnswer.id);
        setQuestionsArray(response.survey.questions);
        response.survey.thankyou_page.variations.forEach((item) => {
          if (item.condition === "CSAT_PDV,entre,1,2") {
            setBadMessage(item.message);
          } else if (item.condition === "CSAT_PDV,igual,3") {
            setGoodMessage(item.message);
          } else {
            setGreatMessage(item.message);
          }
        });
      });
  };

  useEffect(() => {
    if (surveyFrisia && questionsArray.length === 0) {
      getAmplifiqueData(surveyFrisia);
    }
  }, [surveyFrisia]);

  const [isLoading, setIsLoading] = useState(false);

  const lookingForSurvey = async () => {
    if (isLoading) return; 

    setIsLoading(true); 

    const response = await frisiaService
      .getSurvey(pathname.slice(1))
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        if (response?.idSolicitacao) {
          setShowFaces(true);
          setSurveyFrisia(response);
        } else {
          setShowFaces(false);
          setRatingNumber(undefined);
          setQuestionsArray([]);
          if (showOthersAmpQuestions) {
            sendAmplifiqueAnswer(answersArray);
            setShowOthersAmpQuestions(false);
          }
        }
      });
  };

  useEffect(() => {
    const interval = setInterval(lookingForSurvey, 7000);
    return () => {
      clearInterval(interval);
    };
  });

  function requestFullScreen() {
    let elem = document.body;

    if (!document.fullscreenElement) {
      setInFullscreen(!inFullscreen);
      elem.requestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      document.exitFullscreen();
      setInFullscreen(!inFullscreen);
    }
  }

  const addFacesResponse = (buttonNumber: number) => {
    const newAnswer = {
      type: "csat",
      internal_name: "CSAT_PDV",
      answer: buttonNumber,
      isMainMetric: true,
    };

    setAnswersArray((oldAnswersArray) => [...oldAnswersArray, newAnswer]);
  };

  const sendAnswer = (faceNumber: number) => {
    if (faceNumber === 5) {
      sendFrisiaAnswer("MUITO_SATISFEITO");
      sendAmplifiqueAnswer([
        {
          type: "csat",
          internal_name: "CSAT_PDV",
          answer: 5,
          isMainMetric: true,
        },
      ]);
    }
    if (faceNumber === 3) {
      sendFrisiaAnswer("BOM");
      if (questionsArray.length > 1) {
        setRatingNumber(3);
        addFacesResponse(3);
        setShowOthersAmpQuestions(true);
      } else {
        sendAmplifiqueAnswer([
          {
            type: "csat",
            internal_name: "CSAT_PDV",
            answer: 3,
            isMainMetric: true,
          },
        ]);
      }
    }
    if (faceNumber === 1) {
      sendFrisiaAnswer("INSATISFEITO");
      if (questionsArray.length > 1) {
        setRatingNumber(1);
        addFacesResponse(1);
        setShowOthersAmpQuestions(true);
      } else {
        sendAmplifiqueAnswer([
          {
            type: "csat",
            internal_name: "CSAT_PDV",
            answer: 1,
            isMainMetric: true,
          },
        ]);
      }
    }
  };

  const sendAmplifiqueAnswer = (answersArray: Answer[]) => {
    setShowOthersAmpQuestions(false);
    if (surveyAnswerId && surveyFrisia) {
      amplifiqueService
        .sendSurveyResponse(
          {
            answers: answersArray,
          },
          surveyAnswerId,
          surveyFrisia.dsToken
        )
        .then((response) => {
          setOpen(true);
          setTimeout(() => {
            setShowFaces(false);
            eraseAnswers();
            setOpen(false);
          }, 5002);
        });
    }
  };

  const sendFrisiaAnswer = (satisfaction: string) => {
    if (surveyFrisia) {
      frisiaService.sendAnswer({
        cdTotem: pathname.slice(1),
        idSolicitacao: surveyFrisia.idSolicitacao,
        avaliacao: satisfaction,
      });
    }
  };

  const othersAmpliQuestions = () => {
    return (
      <div className="avaliate-container">
        <h1 className="h1-header">Avalie os pontos abaixo</h1>
        <div className="all-question-container">
          {questionsArray
            .filter((item) => item.internal_name !== "CSAT_PDV")
            .map((item, index) => (
              <QuestionRow
                key={index}
                setAnswersArray={setAnswersArray}
                question={item}
              />
            ))}
        </div>
        <div
          style={{
            display: "flex",
            margin: "30px",
            marginTop: "0px",
          }}
        >
          <button className="action-button" onClick={eraseAnswers}>
            Voltar
          </button>
          <button
            style={{ margin: "auto" }}
            className="action-button"
            onClick={() => sendAmplifiqueAnswer(answersArray)}
          >
            Finalizar
          </button>
        </div>
      </div>
    );
  };

  const renderReportOnScreen = useCallback(() => {
    if (showOthersAmpQuestions) {
      return othersAmpliQuestions();
    } else {
      return (
        <div>
          <div style={{ color: "white", fontSize: "26px", padding: "31px" }}>
            {questionsArray.length > 0 &&
              questionsArray[0].variations[0].question}
          </div>
          <div className="buttons-container">
            <button
              onClick={() => sendAnswer(5)}
              className="card-body col-sm-4 card styled-button green"
            >
              <FontAwesomeIcon icon={faFaceGrin} />
              <span>Muito Satisfeito</span>
            </button>
            <button
              onClick={() => sendAnswer(3)}
              className="card-body col-sm-4 card styled-button yellow"
            >
              <FontAwesomeIcon icon={faFaceSmile} />
              <span>Satisfeito</span>
            </button>
            <button
              onClick={() => sendAnswer(1)}
              className="card-body col-sm-4 card styled-button red"
            >
              <FontAwesomeIcon icon={faFaceFrown} />
              <span>Insatisfeito</span>
            </button>
          </div>
        </div>
      );
    }
  }, [
    ratingNumber,
    answersArray,
    surveyAnswerId,
    questionsArray,
    showOthersAmpQuestions,
  ]);

  return (
    <div className="container">
      <div className="row">
        <div
          className="col-md-6"
          style={
            !showFaces
              ? {
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  height: "80vh",
                  alignItems: "center",
                }
              : {}
          }
        >
          <img
            src={logo}
            className="img-logo"
            alt="Imagem do logo da Frisia branco"
          />
        </div>
        {pathname.slice(1) === "" && (
          <div style={{ color: "white", fontSize: "26px", padding: "31px" }}>
            Sem indentificador na url
          </div>
        )}
        {showFaces && renderReportOnScreen()}
        <Modal
          open={open}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <div className="modal">
            {ratingNumber && ratingNumber < 3 ? (
              <span>{badMessage}</span>
            ) : ratingNumber && ratingNumber > 3 ? (
              <span>{greatMessage}</span>
            ) : (
              <span>{goodMessage}</span>
            )}
          </div>
        </Modal>
        <div
          onClick={() => {
            requestFullScreen();
          }}
          style={{
            height: "9vh",
            width: "9vw",
            position: "absolute",
            bottom: "0",
            right: "0",
            margin: "20px",
          }}
        >
          {!inFullscreen && (
            <img
              style={{
                height: "9vh",
                width: "9vw",
              }}
              src={maximize}
              alt="like"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
