import { AxiosError, AxiosResponse } from "axios";
import amplifiqueApi from "../api/api";
import HttpStatus from "../model/enums/http-status";
import { iClientInfo, ISurveyAnswer, ISurveyResponse } from "../model/search";

export const amplifiqueService = () => {
  const getSearchByClient = async (clientInfo: iClientInfo, token: string) => {
    try {
      const result: AxiosResponse<ISurveyResponse> =
        await amplifiqueApi.getSearchByClient(clientInfo, token);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };
  const sendSurveyResponse = async (
    surveyAnswer: ISurveyAnswer,
    id: string,
    token: string
  ) => {
    try {
      const result: AxiosResponse<ISurveyResponse> =
        await amplifiqueApi.sendSurveyResponse(surveyAnswer, id, token);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };
  return {
    getSearchByClient,
    sendSurveyResponse,
  };
};

export default amplifiqueService();
