import { AxiosError, AxiosResponse } from "axios";
import HttpStatus from "../model/enums/http-status";
import frisiaAPI from "../api/api-frisia";
import { IFrisiaAnswer, IFrisiaResponse, IFrisiaSurvey } from "../model/frisiaSurvey";

export const frisiaService = () => {
  const getSurvey = async (cdTotem: string) => {
    try {
      const result: AxiosResponse<IFrisiaSurvey | null> =
        await frisiaAPI.getSurvey(cdTotem);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const sendAnswer = async (answer: IFrisiaAnswer) => {
    try {
      const result: AxiosResponse<IFrisiaResponse | null> =
        await frisiaAPI.sendAnswer(answer);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getSurvey,
    sendAnswer,
  };
};

export default frisiaService();
