import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { iClientInfo, ISurveyAnswer, ISurveyResponse } from "../model/search";

const createApiInstance = (): AxiosInstance => {
  const apiConfig: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_AMPLIFIQUE,
    timeout: 60 * 1000,
  };
  return axios.create(apiConfig);
};

export const api = createApiInstance();

export const amplifiqueApi = () => {
  const getSearchByClient = (
    clientInfo: iClientInfo,
    token: string
  ): Promise<AxiosResponse<ISurveyResponse>> => {
    api.interceptors.request.use(async (configInstance) => {
      if (token != null) {
        configInstance.headers!.Authorization = `Bearer ${token}`;
      }
      return configInstance;
    });

    return api.post<ISurveyResponse>("", clientInfo);
  };

  const sendSurveyResponse = (
    surveyAnswer: ISurveyAnswer,
    id: string,
    token: string
  ): Promise<AxiosResponse<ISurveyResponse>> => {
    api.interceptors.request.use(async (configInstance) => {
      if (token != null) {
        configInstance.headers!.Authorization = `Bearer ${token}`;
      }
      return configInstance;
    });
    return api.put<ISurveyResponse>(`${id}?isFinalize=true`, surveyAnswer);
  };

  return {
    getSearchByClient,
    sendSurveyResponse,
  };
};

export default amplifiqueApi();
