import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { iClientInfo, ISurveyAnswer, ISurveyResponse } from "../model/search";
import { IFrisiaAnswer, IFrisiaResponse, IFrisiaSurvey } from "../model/frisiaSurvey";

const createApiInstance = (): AxiosInstance => {
  const config: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_FRISIA,
    timeout: 60 * 1000,
  };
  const instance = axios.create(config);

  instance.interceptors.request.use(async (configInstance) => {
    const token = process.env.REACT_APP_TOKEN_FRISIA;
    if (token != null) {
      configInstance.headers!.Authorization = `Bearer ${token}`;
    }
    return configInstance;
  });

  return instance;
};

export const api = createApiInstance();

export const frisiaAPI = () => {
  const getSurvey = (cdTotem: string): Promise<AxiosResponse<IFrisiaSurvey | null>> => {
    return api.get<IFrisiaSurvey | null>(`/TotRequestMostrar?cdTotem=${cdTotem}`);
  };

    const sendAnswer = (
      answer: IFrisiaAnswer
    ): Promise<AxiosResponse<IFrisiaResponse | null>> => {
      return api.post<IFrisiaResponse | null>("/TotGravaAvaliacao", answer);
    };

  return {
    getSurvey,
    sendAnswer,
  };
};

export default frisiaAPI();
