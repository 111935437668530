import React, { useEffect } from "react";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import thumbsUp from "../assets/images/thumbs-up-solid.svg";
import thumbsDown from "../assets/images/thumbs-down-solid.svg";
import { Answer, Question } from "../model/search";
import "./styles.css";

interface MyComponentProps {
  question: Question;
  setAnswersArray: React.Dispatch<React.SetStateAction<Answer[]>>;
}

const QuestionRow: React.FC<MyComponentProps> = ({
  question,
  setAnswersArray,
}) => {
  const [chosenValue, setValue] = React.useState<number | null>(null);
  const [chosenLike, setChosenLike] = React.useState<boolean | null>(null);
  const [notAvaliate, setNotAvaliate] = React.useState<boolean>(false);

  const addOrUpdatetAnswer = (chosenAnswer: string | number | null) => {
    if (chosenAnswer && !notAvaliate) {
      const newAnswer = {
        type: question.type,
        internal_name: question.internal_name,
        answer: chosenAnswer,
      };

      setAnswersArray((oldAnswersArray) => {
        const index = oldAnswersArray.findIndex(
          (obj) => obj.internal_name === question.internal_name
        );
        if (index === -1) {
          return [...oldAnswersArray, newAnswer];
        } else {
          const updatedArray = [...oldAnswersArray];
          updatedArray[index] = newAnswer;
          return updatedArray;
        }
      });
    }
  };

  useEffect(() => {
    if (notAvaliate) {
      setAnswersArray((oldAnswersArray) => {
        const updatedArray = oldAnswersArray.filter(
          (obj) => obj.internal_name !== question.internal_name
        );
        return updatedArray;
      });
    }
  }, [notAvaliate]);

  return (
    <div
      className="question-container"
      style={notAvaliate ? { opacity: "0.5" } : {}}
    >
      <h2 style={question.type === "csat" ? {} : { width: "1000px" }}>
        {question.variations[0].question}
      </h2>
      {question.type === "thumbs" && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              display: "flex",
              fontSize: "50px",
              alignItems: "center",
            }}
            onClick={() => {
              addOrUpdatetAnswer("up");
              setChosenLike(true);
            }}
          >
            <img
              style={
                chosenLike
                  ? { height: "90px", opacity: "1", marginRight: "20px" }
                  : { height: "60px", opacity: "0.5", marginRight: "20px" }
              }
              src={thumbsUp}
              alt="like"
            />
            <label>Sim</label>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "50px",
              alignItems: "center",
              marginLeft: "25px",
            }}
            onClick={() => {
              addOrUpdatetAnswer("down");
              setChosenLike(false);
            }}
          >
            <img
              style={
                chosenLike === false
                  ? { height: "90px", opacity: "1", marginRight: "20px" }
                  : { height: "60px", opacity: "0.5", marginRight: "20px" }
              }
              src={thumbsDown}
              alt="dislike"
            />
            <label>Não</label>
          </div>
        </div>
      )}
      {question.type === "csat" && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Rating
            name="simple-controlled"
            className="star"
            value={chosenValue}
            emptyIcon={<StarIcon color="primary" fontSize="inherit" />}
            onChange={(event, newValue) => {
              setValue(newValue);
              addOrUpdatetAnswer(newValue);
            }}
          />
          <button
            onClick={() => {
              setNotAvaliate(!notAvaliate);
              setValue(null);
              setChosenLike(null);
              addOrUpdatetAnswer(null);
            }}
            className="action-button"
          >
            Não avaliar
          </button>
        </div>
      )}
    </div>
  );
};

export default QuestionRow;
